import {hapticFeedback, type TriggerHapticFeedback} from "./index";
import type {FunctionImplementation} from "@otto-ec/global-resources/nexus";
import {AppType, appType} from "../helper";

export const triggerHapticFeedback = function () {
    if (appType() == AppType.ANDROID) {
        window.o_apps_android?.triggerHapticFeedback?.()
    } else if (appType() == AppType.IOS) {
        window.webkit?.messageHandlers?.triggerHapticFeedback?.postMessage({});
    }
} satisfies FunctionImplementation<TriggerHapticFeedback>

hapticFeedback.assignNamespace({triggerHapticFeedback})