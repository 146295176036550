import type {OttoNexus, QueuableFunction} from "@otto-ec/global-resources/nexus";
import {apps} from "../index";

export type HapticFeedback = {
    triggerHapticFeedback: QueuableFunction<TriggerHapticFeedback>;
}

export type TriggerHapticFeedback =
/**
 *
 */
    () => void;

/**
 *
 */
export const hapticFeedback: OttoNexus["apps"]["hapticFeedback"] = apps.hapticFeedback;

export const {
    /**
 *
 */
    triggerHapticFeedback
} = hapticFeedback;